exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-js-content-file-path-content-md-a-dozen-best-practices-fo-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/a-dozen-best-practices-fo.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-a-dozen-best-practices-fo-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-allowing-your-players-to-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/allowing-your-players-to-.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-allowing-your-players-to-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-an-esssential-marketing-m-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/an-esssential-marketing-m.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-an-esssential-marketing-m-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-are-you-counting-too-much-2-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/are-you-counting-too-much-2.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-are-you-counting-too-much-2-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-are-you-counting-too-much-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/are-you-counting-too-much.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-are-you-counting-too-much-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-cache-creek-cybersecurity-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/cache-creek-cybersecurity.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-cache-creek-cybersecurity-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-can-obamas-braintrust-rea-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/can-obamas-braintrust-rea.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-can-obamas-braintrust-rea-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-chinese-government-a-mode-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/chinese-government-a-mode.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-chinese-government-a-mode-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-confession-bad-talk-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/confession-bad-talk.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-confession-bad-talk-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-deploying-http-4-k-dokku-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/deploying-http4k-dokku.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-deploying-http-4-k-dokku-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-deploying-http-4-k-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/deploying-http4k.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-deploying-http-4-k-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-election-day-edition-laff-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/election-day-edition-laff.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-election-day-edition-laff-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-forget-the-dire-forecasts-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/forget-the-dire-forecasts.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-forget-the-dire-forecasts-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-horseshoe-bend-10-reasons-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/horseshoe-bend-10-reasons.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-horseshoe-bend-10-reasons-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-how-should-casinos-deal-w-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/how-should-casinos-deal-w.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-how-should-casinos-deal-w-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-iga-conf-2024-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/iga-conf-2024.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-iga-conf-2024-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-iga-webcast-cybersecurity-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/iga-webcast-cybersecurity.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-iga-webcast-cybersecurity-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-introducing-cap-coop-cf-14-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/introducing-cap-coop-cf14.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-introducing-cap-coop-cf-14-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-jackie-gaughan-1920-2014-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/jackie-gaughan-1920-2014-.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-jackie-gaughan-1920-2014-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-kotlin-dataframe-100-examples-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/kotlin-dataframe-100-examples.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-kotlin-dataframe-100-examples-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-kotlin-dataframe-sdkotlin-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/kotlin-dataframe-sdkotlin.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-kotlin-dataframe-sdkotlin-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-kotlinconf-23-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/kotlinconf-23.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-kotlinconf-23-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-monopoly-promo-math-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/monopoly-promo-math.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-monopoly-promo-math-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-nfl-win-probability-part-1-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/nfl-win-probability-part-1.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-nfl-win-probability-part-1-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-nfl-win-probability-part-2-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/nfl-win-probability-part-2.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-nfl-win-probability-part-2-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-opentelemetry-http-4-k-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/opentelemetry-http4k.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-opentelemetry-http-4-k-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-revenue-vs-gtr-f-13-cebd-72-c-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/revenue-vs-gtr-f13cebd72c.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-revenue-vs-gtr-f-13-cebd-72-c-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-scattered-spider-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/scattered-spider.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-scattered-spider-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-severely-flawed-slot-mach-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/severely-flawed-slot-mach.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-severely-flawed-slot-mach-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-speeding-up-jupyter-python-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/speeding-up-jupyter-python.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-speeding-up-jupyter-python-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-speeding-up-kotlin-console-app-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/speeding-up-kotlin-console-app.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-speeding-up-kotlin-console-app-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-supreme-courts-gambling-c-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/supreme-courts-gambling-c.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-supreme-courts-gambling-c-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-theo-and-actual-eventuall-2-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/theo-and-actual-eventuall-2.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-theo-and-actual-eventuall-2-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-theo-and-actual-eventuall-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/theo-and-actual-eventuall.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-theo-and-actual-eventuall-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-two-gamblers-walk-into-a-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/two-gamblers-walk-into-a-.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-two-gamblers-walk-into-a-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-usgas-lame-rules-are-to-b-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/usgas-lame-rules-are-to-b.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-usgas-lame-rules-are-to-b-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-vip-cutomer-influence-part-1-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/vip-cutomer-influence-part-1.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-vip-cutomer-influence-part-1-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-vip-cutomer-influence-part-2-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/vip-cutomer-influence-part-2.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-vip-cutomer-influence-part-2-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-vip-cutomer-influence-part-3-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/vip-cutomer-influence-part-3.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-vip-cutomer-influence-part-3-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-why-are-so-many-of-my-pla-2-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/why-are-so-many-of-my-pla-2.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-why-are-so-many-of-my-pla-2-mdx" */),
  "component---src-templates-article-js-content-file-path-content-md-why-are-so-many-of-my-pla-mdx": () => import("./../../../src/templates/article.js?__contentFilePath=/opt/build/repo/content/md/why-are-so-many-of-my-pla.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-content-md-why-are-so-many-of-my-pla-mdx" */)
}

